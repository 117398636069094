import { Alert, Button, Card, Skeleton, Table, Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";

import { InfoCircleTwoTone } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useAuth } from "../../contexts/AuthContext";

export default function PatientsList() {

    const { supabase } = useAuth();
    const [patients, setPatients] = useState<any[]>([]);
    const [visits, setVisits] = useState<any[]>([]);
    const [error, setError] = useState<any>(null);

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const getPatients = async () => {
            setLoading(true);
            let { data: patients, error } = await supabase
                .from('patients')
                .select(`
                    *,
                    tutors: tutorsUid (
                        firstname,
                        lastname,
                        email,
                        phone
                    ),
                    relations: relationUid(
                        uid,
                        type
                    )
                `)
                .order('location', { ascending: true });
            setPatients(patients || []);
            setError(error);
            setLoading(false);
        }
        const getVisits = async () => {
            setLoading(true);
            let { data: visits, error } = await supabase
                .from('visits')
                .select(`
                    *,
                    visitsType: visitsTypeUid (
                        name,amount
                    )
                `)
                .order('date', { ascending: false });
            setVisits(visits || []);
            setError(error);
            setLoading(false);
        }
        getPatients();
        getVisits();
    }, [supabase])

    const getDistinctLocations = (patients: any[]) => {
        const locations = patients.map(patient => patient.location);
        return Array.from(new Set(locations));
    }

    const getLastVisit = (patient: any) => {
        console.log("👉 ~ getLastVisit ~ patient:", patient, visits);
        const lastVisit = visits.filter(visit => visit.patientsUid === patient.uid);
        console.log("👉 ~ getLastVisit ~ lastVisit:", lastVisit);
        if (lastVisit.length > 0) {
            return lastVisit[0].date;
        }
        return "No visits";
    }

    const getPaymentUnrrequestedVisits = (patient: any) => {
        const unpaidVisits = visits.filter(visit => visit.patientsUid === patient.uid && visit.paymentRequested === false);
        return unpaidVisits;
    }

    const getUnpaidVisits = (patient: any) => {
        const unpaidVisits = visits.filter(visit => visit.patientsUid === patient.uid && visit.paid === false);
        return unpaidVisits;
    }

    if (loading) return <Skeleton active />

    return (
        <>
            {(patients && visits && error === null) &&

                <Card
                    title="Patients"
                    extra={<NavLink to="/patients/add"><Button type="primary"><PlusCircleOutlined /> Add</Button></NavLink>}
                >
                    {patients.length > 0 && visits.length > 0 ?
                        <Table
                            scroll={{ x: "max-content" }}
                            dataSource={patients}
                            pagination={{
                                showSizeChanger: patients.length > 10,
                                pageSizeOptions: ['10', '20', '30', '40', '50'],
                                defaultPageSize: 20
                            }}
                            rowKey="uid"
                            columns={
                                [
                                    {
                                        title: 'Prefix',
                                        dataIndex: 'prefix',
                                        key: 'prefix',
                                    },
                                    {
                                        title: 'Last Name',
                                        dataIndex: 'lastname',
                                        key: 'lastname',
                                        sorter: (a, b) => a.lastname.localeCompare(b.lastname),
                                    },
                                    {
                                        title: 'First Name',
                                        dataIndex: 'firstname',
                                        key: 'firstname',
                                        sorter: (a, b) => a.firstname.localeCompare(b.firstname),
                                    },
                                    {
                                        title: 'Location',
                                        dataIndex: 'location',
                                        key: 'location',
                                        filters: getDistinctLocations(patients).map(location => ({ text: location, value: location })),
                                        onFilter: (value, record) => record.location === value,
                                    },
                                    {
                                        title: 'Tutor',
                                        dataIndex: 'tutors',
                                        key: 'tutors',
                                        render: (tutor) => <Tooltip title={`${tutor.email}`}>{tutor.firstname} {tutor.lastname} <InfoCircleTwoTone /></Tooltip>
                                    },
                                    {
                                        title: 'Tutor relation with patient',
                                        dataIndex: 'relations',
                                        key: 'relations',
                                        render: (relation) => relation?.type
                                    },
                                    {
                                        title: "Unrequested payments",
                                        dataIndex: "paymentsUnrequested",
                                        key: "paymentsUnrequested",
                                        render: (text, record) => getPaymentUnrrequestedVisits(record).map((visit: any) => (
                                            <p key={visit.uid}><Tag color="blue">{dayjs(visit.date).format('DD/MM/YYYY')}</Tag></p>
                                        ))
                                    },
                                    // {
                                    //     title:"Unpaid visits",
                                    //     dataIndex:"unpaidVisits",
                                    //     key:"unpaidVisits",
                                    //     render:(text,record)=>getUnpaidVisits(record).map((visit:any)=>(
                                    //         <p key={visit.uid}><Tag color="geekblue">{dayjs(visit.date).format('DD/MM/YYYY')}</Tag></p>
                                    //     ))
                                    // },
                                    {
                                        title: "Last visit",
                                        dataIndex: "lastVisit",
                                        key: "lastVisit",
                                        render: (text, record) => <Tag color="green">{dayjs(getLastVisit(record)).format('DD/MM/YYYY')}</Tag>,
                                        sorter: (a, b) => new Date(getLastVisit(a)).getTime() - new Date(getLastVisit(b)).getTime(),
                                    },
                                    {
                                        title: 'Action',
                                        key: 'action',
                                        render: (text, record) => (
                                            <NavLink to={`/patients/edit/${record.uid}`}>
                                                Edit
                                            </NavLink>
                                        ),
                                    }
                                ]
                            } />
                        :
                        <Alert
                            message="No patients"
                            description="There are no patients to show."
                            type="info"
                            showIcon
                        />
                    }
                </Card>
            }
        </>
    )
}
