import { Alert, Button, Card, Drawer, Flex, Popconfirm, Skeleton, Table, Tooltip, Typography } from 'antd';
import { CheckCircleOutlined, FileDoneOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { useEffect, useState } from 'react'

import dayjs from 'dayjs';
import { useAuth } from '../../contexts/AuthContext';

export default function Payments() {

    const { supabase } = useAuth();
    const [patients, setPatients] = useState<any[]>([]);
    const [groupedVisitsUnrequested, setGroupedVisitsUnrequested] = useState<any[]>([]);
    const [groupedVisitsUnpaid, setGroupedVisitsUnpaid] = useState<any[]>([]);
    const [tutors, setTutors] = useState<any[]>([]);
    const [error, setError] = useState<any>(null);
    const [profile, setProfile] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [drawerContent, setDrawerContent] = useState<any>(null);
    const [refreshCounter, setRefreshCounter] = useState<number>(0);


    useEffect(() => {
        const getUser = async () => {
            setLoading(true);
            const { data, error } = await supabase
                .from('profiles')
                .select('*')
            if (data && data.length > 0) {
                setProfile(data[0]);
            }
            if (error) {
                setError(error)
            }
            setLoading(false)
        }
        const getPatients = async () => {
            setLoading(true);
            let { data: patients, error } = await supabase
                .from('patients')
                .select(`
                *,
                tutors: tutorsUid (
                firstname,
                lastname,
                email,
                phone
                ),
                relations: relationUid(
                uid,
                type
                )
                `)
                .order('firstname', { ascending: true });
            setPatients(patients || []);
            setError(error);
            setLoading(false);
        }

        const getVisits = async () => {
            setLoading(true);
            let { data: visits, error } = await supabase
                .from('visits')
                .select(`
                    *,
                    patients: patientsUid (
                    firstname,
                    lastname
                    ),
                    visitsType: visitsTypeUid (
                    name,amount
                    )
                    `)
                .order('date', { ascending: false });

            const groupedVisitsUnrequested = visits && visits.reduce((acc, visit) => {
                if (!visit.paymentRequested && !visit.paid) {
                    const { patientsUid } = visit;
                    let patientGroup = acc.find((group: any) => group.patientsUid === patientsUid);
                    if (!patientGroup) {
                        patientGroup = { patientsUid, visits: [] };
                        acc.push(patientGroup);
                    }
                    patientGroup.visits.push(visit);
                }
                return acc;
            }, []);
            setGroupedVisitsUnrequested(groupedVisitsUnrequested || []);

            const groupedVisitsUnpaid = visits && visits.reduce((acc, visit) => {
                if (visit.paymentRequested && !visit.paid) {
                    const { patientsUid } = visit;
                    let patientGroup = acc.find((group: any) => group.patientsUid === patientsUid);
                    if (!patientGroup) {
                        patientGroup = { patientsUid, visits: [] };
                        acc.push(patientGroup);
                    }
                    patientGroup.visits.push(visit);
                }
                return acc;
            }, []);
            setGroupedVisitsUnpaid(groupedVisitsUnpaid || []);
            setError(error);
            setLoading(false);
        }
        const getTutors = async () => {
            setLoading(true);
            let { data: tutors, error } = await supabase
                .from('tutors')
                .select('*')
                .order('firstname', { ascending: true });
            setTutors(tutors || []);
            setError(error);
            setLoading(false);
        }
        getUser()
        getVisits();
        getPatients();
        getTutors();
    }, [supabase, refreshCounter])

    const setVisitsAsRequested = async (patientUid: string) => {
        const patientVisits = groupedVisitsUnrequested.filter((group: any) => group.patientsUid === patientUid);
        const visitsUid = patientVisits.map((group: any) => group.visits.map((visit: any) => visit.uid)).flat();

        setLoading(true);
        let { error } = await supabase
            .from('visits')
            .update({ paymentRequested: true })
            .in('uid', visitsUid);
        setError(error);
        setLoading(false);
        setRefreshCounter(refreshCounter + 1);
    }

    const setAsPaid = async (patientUid: string) => {
        const patientVisits = groupedVisitsUnpaid.filter((group: any) => group.patientsUid === patientUid);
        const visitsUid = patientVisits.map((group: any) => group.visits.map((visit: any) => visit.uid)).flat();

        setLoading(true);
        let { error } = await supabase
            .from('visits')
            .update({ paid: true })
            .in('uid', visitsUid);
        setError(error);
        setLoading(false);
        setRefreshCounter(refreshCounter + 1);
    }


    const generateInvoice = async (patientUid: string) => {

        const patient = patients.find(patient => patient.uid === patientUid);
        const tutor = tutors.find(tutor => tutor.uid === patient?.tutorsUid);

        const { data: patientVisits, error } = await supabase
            .from('visits')
            .select(`
                *,
                patients: patientsUid (
                    firstname,
                    lastname
                ),
                visitsType: visitsTypeUid (
                    name,amount
                )
            `)
            .eq('patientsUid', patientUid)
            .eq('paid', false);
        setError(error);

        setDrawerOpen(true);

        const mailContent = <div>
            <p>Bonjour {(tutor.prefix) ? tutor.prefix : tutor.firstname} {tutor.lastname},</p>

            <p>Je reviens vers vous concernant les visites médicales effectuées auprès de votre {patient && patient.relations.type}, {patient && patient.prefix} {patient && patient.lastname}.</p>

            <p>{patientVisits?.length} {patientVisits && patientVisits?.length > 1 ? "visites ont été effectuées" : "visite a été effectuée"} pour un montant total de {patientVisits?.reduce((acc, visit) => acc + visit.visitsType.amount, 0)} €.</p>

            <p>
                Vous trouverez, ci-dessous, le détails des visites :

            </p>
            <ul>
                {patientVisits?.sort((a: any, b: any) => dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1).map((visit: any) => (
                    <li key={visit.uid}>Le {dayjs(visit.date).format('DD/MM/YYYY')} ({visit.visitsType.name}): {visit.visitsType.amount} €</li>
                ))}
            </ul>

            <p>
                Je vous demanderais,s'il vous plait, de bien vouloir régler la somme de {patientVisits?.reduce((acc, visit) => acc + visit.visitsType.amount, 0)} € sur le compte suivant : <b>{profile?.accountNumber}</b> avec la communication suivante : "  <b>Visites {patient && patient.firstname} {patient && patient.lastname} - {dayjs().format('MM/YYYY')}</b>".
            </p>

            <p>
                Bien à vous,
            </p>
            <p>
                {profile?.communicationSignature}
            </p>
        </div>

        const mailBody = encodeURIComponent(`Bonjour ${(tutor.prefix) ? tutor.prefix : tutor.firstname} ${tutor.lastname},

Je reviens vers vous concernant les visites médicales effectuées auprès de votre ${patient && patient.relations.type}, ${patient && patient.prefix} ${patient && patient.lastname}.

${patientVisits?.length} ${patientVisits && patientVisits?.length > 1 ? "visites ont été effectuées" : "visite a été effectuée"} pour un montant total de ${patientVisits?.reduce((acc, visit) => acc + visit.visitsType.amount, 0)} €.

Vous trouverez, ci-dessous, le détails des visites :

${patientVisits?.sort((a: any, b: any) => dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1)?.map((visit: any) => `- Le ${dayjs(visit.date).format('DD-MM-YYYY')} - ${visit.visitsType.name}: ${visit.visitsType.amount} €`).join('\n')}

Je vous demanderais donc de bien vouloir régler la somme de ${patientVisits?.reduce((acc, visit) => acc + visit.visitsType.amount, 0)} € sur le compte suivant : ${profile?.accountNumber} avec la communication suivante : "Visites ${patient && patient.firstname} ${patient && patient.lastname} - ${dayjs().format('MM/YYYY')}".

Bien à vous,

${profile?.communicationSignature}
        `);

        setDrawerContent(<Flex vertical gap="middle">
            <Typography.Title level={4}>Send invoice to {tutor?.firstname} {tutor?.lastname}</Typography.Title>
            {mailContent}
            <a href={`mailto:${tutor?.email}?subject=${profile?.communicationHeader}&body=${mailBody}`}><Button type="primary">Open Mail App to send this message</Button></a>
        </Flex>)
    }

    if (loading) return <Skeleton active />
    return (
        <>
            {(groupedVisitsUnrequested && error === null) &&
                <Flex vertical gap="middle">
                    <Card
                        title="Payments to request"
                    >
                        {groupedVisitsUnrequested.length > 0 ?
                            <Table
                                scroll={{ x: "max-content" }}
                                dataSource={groupedVisitsUnrequested}
                                pagination={{
                                    showSizeChanger: groupedVisitsUnrequested.length > 10,
                                    pageSizeOptions: ['10', '20', '30', '40', '50'],
                                    defaultPageSize: 20
                                }}
                                rowKey="patientsUid"
                                columns={
                                    [
                                        {
                                            title: 'Patient',
                                            dataIndex: 'patientsUid',
                                            key: 'patientsUid',
                                            render: (patientsUid: string) => {
                                                const patient = patients.find(patient => patient.uid === patientsUid);
                                                return patient ? `${patient.firstname} ${patient.lastname}` : 'Unknown';
                                            }
                                        },
                                        {
                                            title: 'Visits',
                                            dataIndex: 'visits',
                                            key: 'visits',
                                            render: (visits: any[]) => {
                                                return <Tooltip title={visits.map((visit: any) => dayjs(visit.date).format('DD-MM-YYYY') + ' (' + visit.visitsType.name + ')').join(',\n')}>
                                                    {visits.length}
                                                    {" "}
                                                    <InfoCircleTwoTone /></Tooltip>
                                            }
                                        },
                                        {
                                            title: 'Total',
                                            dataIndex: 'visits',
                                            key: 'visits',
                                            render: (visits: any[]) => visits.reduce((acc, visit) => acc + visit.visitsType.amount, 0) + ' €'
                                        },
                                        {
                                            title: 'Action',
                                            key: 'action',
                                            render: (text: any, record: any) => (
                                                <Flex gap="small">
                                                    <Button type="default" onClick={() => generateInvoice(record.patientsUid)}>
                                                        <FileDoneOutlined /> Generate invoice
                                                    </Button>
                                                    <Popconfirm
                                                        title="Are you sure you want to set this payment as requested?"
                                                        onConfirm={() => setVisitsAsRequested(record.patientsUid)}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button type="primary">
                                                            <CheckCircleOutlined /> Set payment as requested
                                                        </Button>
                                                    </Popconfirm>
                                                </Flex>
                                            ),
                                        }

                                    ]
                                }
                            />
                            :
                            <Alert
                                message="No payments due"
                                description="There are no payments to show."
                                type="info"
                                showIcon
                            />
                        }
                    </Card>
                    <Card
                        title="Payments requested (unpaid)"
                    >
                        {groupedVisitsUnpaid.length > 0 ?
                            <Table
                                scroll={{ x: "max-content" }}
                                dataSource={groupedVisitsUnpaid}
                                pagination={{
                                    showSizeChanger: groupedVisitsUnpaid.length > 10,
                                    pageSizeOptions: ['10', '20', '30', '40', '50'],
                                    defaultPageSize: 20
                                }}
                                rowKey="patientsUid"
                                columns={
                                    [
                                        {
                                            title: 'Patient',
                                            dataIndex: 'patientsUid',
                                            key: 'patientsUid',
                                            render: (patientsUid: string) => {
                                                const patient = patients.find(patient => patient.uid === patientsUid);
                                                return patient ? `${patient.firstname} ${patient.lastname}` : 'Unknown';
                                            }
                                        },
                                        {
                                            title: 'Visits',
                                            dataIndex: 'visits',
                                            key: 'visits',
                                            render: (visits: any[]) => {
                                                return <Tooltip title={visits.map((visit: any) => visit.date + ' (' + visit.visitsType.name + ')').join(',\n')}>
                                                    {visits.length}
                                                    {" "}
                                                    <InfoCircleTwoTone /></Tooltip>
                                            }
                                        },
                                        {
                                            title: 'Total',
                                            dataIndex: 'visits',
                                            key: 'visits',
                                            render: (visits: any[]) => visits.reduce((acc, visit) => acc + visit.visitsType.amount, 0) + ' €'
                                        },
                                        {
                                            title: 'Action',
                                            key: 'action',
                                            render: (text: any, record: any) => (
                                                <Flex gap="small">
                                                    <Popconfirm
                                                        title="Are you sure you want to set this payment as paid?"
                                                        onConfirm={() => setAsPaid(record.patientsUid)}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button type="primary">
                                                            <CheckCircleOutlined /> Set as paid
                                                        </Button>
                                                    </Popconfirm>
                                                </Flex>
                                            ),
                                        }

                                    ]
                                }
                            />
                            :
                            <Alert
                                message="No payments due"
                                description="There are no payments to show."
                                type="info"
                                showIcon
                            />
                        }
                    </Card>
                    <Drawer
                        title="Invoice"
                        placement="right"
                        closable={true}
                        onClose={() => { setDrawerOpen(false) }}
                        open={drawerOpen}
                        size={'large'}
                    >
                        {drawerContent}
                    </Drawer>
                </Flex>

            }
        </>
    )
}
